import { IListData, IItem, ITableCol } from './../interfaces/interfaces';
import tippyJs from 'tippy.js';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

export class Floors {
  wrap: HTMLElement;
  floors: NodeListOf<SVGElement>;
  flrs: NodeListOf<SVGPathElement>;
  switcher: HTMLFormElement;
  data: IListData;

  constructor(selector: string, data: IListData) {
    const wrap: HTMLElement = document.querySelector(selector);

    if (data) {
      this.data = data;
    }

    if (wrap) {
      this.wrap = wrap;
      this.setupFloors();
    }
  }

  private setupFloors(): void {

    const switcher: HTMLFormElement = this.wrap.querySelector('.floors__switcher');
    const flrs: NodeListOf<SVGPathElement> = this.wrap.querySelectorAll('[data-flr]');
    const floors: NodeListOf<SVGElement> = this.wrap.querySelectorAll('[data-floor]:not([data-floor="roof"])');

    if (floors) {
      this.floors = floors;

      if (flrs) {
        this.flrs = flrs;
        this.setFlrsSwitcher();
      }

      if (switcher) {
        this.switcher = switcher;
        this.switcher ? this.switcher.onchange = (e: Event) => this.switchFloors(parseInt((e.target as HTMLInputElement).value, 10)) : '';
      }
    }

    if (this.data) {
      this.setupTippies();
    } else {
      this.getData();
    }
  }

  private setFlrsSwitcher() {
    this.flrs.forEach((flr: SVGPathElement) => {
      flr.onclick = () => {
        const to: number = parseInt(flr.getAttribute('data-flr'), 10);
        this.switchFloors(to);
      };
    });
  }

  private getData() {
    this.dataRequest().then(() => {
      this.setupTippies();
    });
  }

  private async dataRequest(): Promise<any> {
    const config: AxiosRequestConfig = {
      method: 'get',
      url: forJs.requestUrl,
      params: {
        action: 'getListData',
      },
    };

    return await axios(config).then((resp: AxiosResponse) => {
      if (resp.data) {
        this.data = resp.data;
      }

    }).catch((error: AxiosError) => {
      console.log(error.response);
    });
  }

  private switchFloors(to: number) {
    const currentInput: HTMLInputElement = this.switcher.querySelector(`[value="${to}"]`);

    if (!currentInput.checked) {
      currentInput.checked = true;
    }

    const selected: SVGElement = Array.from(this.floors).find((floor: SVGElement) => {
      return parseInt(floor.getAttribute('data-floor'), 10) === to;
    });

    const active: SVGElement = selected.parentElement.querySelector('.active');

    active.classList.remove('active');
    selected.classList.add('active');
  }

  private setupTippies() {

    const notsolds: NodeListOf<SVGPathElement> = this.wrap.querySelectorAll('.not');
    const all_1: SVGPathElement = this.wrap.querySelector('.all_1');
    const all_2: SVGPathElement = this.wrap.querySelector('.all_2');
    let areaTitle: string = '';
    let roomsTitle: string = '';

    this.data.apartments.tablecols.forEach((tablecol: ITableCol) => {
      if (tablecol.slug === 'area') {
        areaTitle = tablecol.title;
      }

      if (tablecol.slug === 'rooms') {
        roomsTitle = tablecol.title;
      }
    });

    this.data.apartments.items.forEach((item: IItem) => {
      const { apartment, area, status, rooms, statusTitle, house } = item;
      const apartmentPath: SVGPathElement = this.wrap.querySelector(`[data-apartment="${item.house}_${item.apartment}"]`);

      if (!status) {
        apartmentPath.classList.add('sold');
        apartmentPath.parentElement.removeAttribute('xlink:href');
      } else {
        apartmentPath.parentElement.setAttribute('xlink:href', item.url);
      }

      const tableTemp: string = status ? `<div class="tooltip__table">
        <div class="tooltip__row">
          <p class="th">${ areaTitle }</p>
          <p>${ area }</p>
        </div>
        <div class="tooltip__row">
          <p class="th">${ roomsTitle }</p>
          <p>${ rooms }</p>
        </div>
      </div>` : '';

      const template: string = `
        <div class="tooltip">
          <div class="tooltip__apartment ${ status ? '' : 'tooltip__apartment--sold' }">
            <div class="tooltip__top">
              <h4>${ apartment }</h4>
              <p>${ statusTitle }</p>
            </div>
            ${ tableTemp }
          </div>
        </div>
      `;

      tippyJs(apartmentPath, {
        animation: 'fade',
        content: template
      });
    });

    if (notsolds){
        notsolds.forEach((notsold: SVGPathElement) => {
            const template: string = `
        <div class="tooltip">
          <div class="tooltip__notsold">
            <div class="tooltip__icon"></div>
            <p>${ this.data.notsold }</p>
          </div>
        </div>
      `;

            // tippyJs(notsold, {
            //     animation: 'fade',
            //     content: template
            // });
        });
    }

    if (all_1) {
      const template: string = `
        <div class="tooltip">
          <div class="tooltip__all">
            <div class="tooltip__icon"></div>
            <h4>${ this.data.free[0].value }</h4>
            <p>${ this.data.free[0].title }</p>
          </div>
        </div>
      `;

      tippyJs(all_1, {
        animation: 'fade',
        content: template
      });

      if (window.innerWidth > 991) {
        (all_1 as any)._tippy.show();
      }
    }

    if (all_2) {
        const template: string = `
      <div class="tooltip">
        <div class="tooltip__all">
          <div class="tooltip__icon"></div>
          <h4>${ this.data.free[1].value }</h4>
          <p>${ this.data.free[1].title }</p>
        </div>
      </div>
        `;

      tippyJs(all_2, {
          animation: 'fade',
          content: template
      });

      if (window.innerWidth > 991) {
          (all_2 as any)._tippy.show();
      }
    }
  }
}
