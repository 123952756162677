export class Visuals {
  constructor() {
    const input: HTMLInputElement = document.querySelector('#switch_3d_2d');

    if (input) {
      input.onchange = () => {
        if (input.checked) {
          document.querySelector('#visual-2d').classList.add('apartment__visual--show');
          document.querySelector('#visual-3d').classList.remove('apartment__visual--show');
        } else {
          document.querySelector('#visual-2d').classList.remove('apartment__visual--show');
          document.querySelector('#visual-3d').classList.add('apartment__visual--show');
        }
      };
    }
  }
}
