import { scrollTo } from './common';

export class ScrollTos {
  constructor(selector: string) {
    const btns: NodeListOf<HTMLElement> = document.querySelectorAll(selector);

    btns.forEach((btn: HTMLElement) => {
      btn.addEventListener('click', () => {
        const to: number = (document.querySelector(btn.getAttribute('data-scrollto')) as HTMLElement).offsetTop;
        scrollTo(to);
      });
    });
  }
}
