import { IListData, IListTypeData, ITableCol, IItem } from '../interfaces/interfaces';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import perfectScrollbar from 'perfect-scrollbar';

export class Offers {
  data: IListData;
  list: HTMLElement;
  tabs: HTMLFormElement;
  filters: HTMLElement;
  tables: HTMLElement;
  tablesScroll: perfectScrollbar;

  constructor(selector: string) {
    const list: HTMLElement = document.querySelector(selector);

    if (list) {
      this.list = list;
      this.tabs = this.list.querySelector('.tabs__form');
      this.filters = this.list.querySelector('.filters__wrap');
      this.tables = this.list.querySelector('.list__tables');

      this.getListData();
    }
  }

  private getListData(): void {
    this.listDataRequest().then(() => {
      this.list.classList.add('list--loaded');
      this.setupTabs();
    });
  }

  private async listDataRequest(): Promise<any> {
    const config: AxiosRequestConfig = {
      method: 'get',
      url: forJs.requestUrl,
      params: {
        action: 'getOffersData',
      },
    };

    return await axios(config).then((resp: AxiosResponse) => {
      if (resp.data) {
        this.data = resp.data;
      }

    }).catch((error: AxiosError) => {
      console.log(error.response);
    });
  }

  private setupTabs(): void {
    const { apartments } = this.data;
    this.addList(apartments);

    this.tablesScroll = new perfectScrollbar(this.tables, {
      wheelPropagation: true
    });
    this.tables.scrollLeft = 0;
  }

  private addList(data: IListTypeData): void {
    const list: string = `
      <div class="list__table">
        ${ this.getListHead(data.tablecols) }
        ${ this.getListBody(data.tablecols, data.items) }
      </div>
    `;

    this.list.querySelector('.list__tables').insertAdjacentHTML('beforeend', list);
  }

  private getListHead(tablecols: ITableCol[]): string {
    return `
      <div class="list__head">
        <div class="container list__head">
          <div class="wrap list__row">
            ${ tablecols.map((tablecol: ITableCol) => {
              return `
                <div class="list__col">
                  <h5>${tablecol.title}</h5>
                </div>
              `;
            }).reduce((total, cur) => {
              return `${total}${cur}`;
            }) }
          </div>
        </div>
      </div>
    `;
  }

  private getListBody(tablecols: ITableCol[], items: IItem[]): string {
    return `
      ${ items.map((item: IItem) => {
        const { id, status, url } = item;
        return `
          <a
            href="${url}"
            id="list-${id}"
            data-id="${id}"
            class="list__item list__item--${ status ? 'free' : 'sold' } list__item--show"
          >
            <div class="container">
              <div class="wrap list__row">
                ${ tablecols.map((tablecol: ITableCol) => {
                  const value: string = (item as any)[tablecol.slug];

                  if (tablecol.slug === 'price') {
                    return `
                    <div class="list__col list__col--offer">
                      <p class="wide">${ value[0] }</p>
                      <p>${ value[1] }</p>
                      <p class="old">${ value[2] }</p>
                    </div>
                  `;
                  }

                  return `
                    <div class="list__col">
                      <p>${ value }</p>
                    </div>
                  `;
                }).reduce((total, cur) => {
                  return `${total}${cur}`;
                }) }
              </div>
            </div>
          </a>
        `;
      }).reduce((total, cur) => {
        return `${total}${cur}`;
      }) }
    `;
  }
}
